import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';

export const routeTransitionAnimations = trigger('triggerName', [
	transition('shoppinglist => items, shoppinglist => recipes, shoppinglist => stores, shoppinglist => settings, items => recipes, items => stores, items => settings, recipes => stores, recipes => settings, stores => settings', [
		style({ position: 'relative' }),
		query(':enter, :leave', [
			style({
				position: 'absolute',
				top: 0,
				right: 0,
				width: '100%',
				bottom: 0
			})
		]),
		query(':enter', [style({ right: '-100%', opacity: 0 })]),
		query(':leave', animateChild()),
		group([
			query(':leave', [animate('300ms ease-out', style({ transform: 'translateX(-100%)', opacity: 0 }))]),
			query(':enter', [animate('300ms ease-out', style({ transform: 'translateX(-100%)', opacity: 1 }))])
		]),
		query(':enter', animateChild())
	]),
	transition('settings => stores, settings => recipes, settings => items, settings => shoppinglist, stores => recipes, stores => items, stores => shoppinglist, recipes => items, recipes => shoppinglist, items => shoppinglist', [
		style({ position: 'relative' }),
		query(':enter, :leave', [
			style({
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				bottom: 0
			})
		]),
		query(':enter', [style({ left: '-100%', opacity: 0 })]),
		query(':leave', animateChild()),
		group([
			query(':leave', [animate('300ms ease-out', style({ transform: 'translateX(100%)', opacity: 0 }))]),
			query(':enter', [animate('300ms ease-out', style({ transform: 'translateX(100%)', opacity: 1 }))])
		]),
		query(':enter', animateChild())
	])
]);
