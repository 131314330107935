import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SKIP_PWA_INSTALL_PROMPT } from '../services/pwa.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'app-install-pwa',
    templateUrl: './install-pwa.component.html',
    styleUrls: ['./install-pwa.component.css'],
    standalone: true,
    imports: [MatToolbarModule, MatButtonModule, MatIconModule, NgIf, TranslocoPipe]
})
export class InstallPwaComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { mobileType: 'ios' | 'android', promptEvent?: { prompt: () => void } },
    private bottomSheetRef: MatBottomSheetRef<InstallPwaComponent>
  ) { }

  installPwa(): void {
    this.data.promptEvent?.prompt();
    this.close();
  }

  dontAskAgain(): void {
    localStorage.setItem(SKIP_PWA_INSTALL_PROMPT, 'true');
    this.bottomSheetRef.dismiss();
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }
}
