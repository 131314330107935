import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import packageJson from '../../../package.json';

@Injectable({
    providedIn: 'root'
})
export class ReleaseNotesService {
    // Only bump major and minor versions, the patch versions are handled during CI build.
    // The major and minor versions come from the package.json so update those.
    private readonly releasedVersions: string[] = [
        '1.2.0',
        '1.3.0',
    ];

    constructor(
        private readonly snackBar: MatSnackBar,
        private readonly transloco: TranslocoService
    ) { }

    checkReleaseNotes() {
        const lastKnownVersion = localStorage.getItem('version') || '1.0.0';
        const currentVersion = packageJson.version;
        if (lastKnownVersion !== currentVersion) {
            const releaseNotes = this.getNewReleaseNotes(lastKnownVersion);
            if (releaseNotes?.length > 0) {
                const version = releaseNotes[releaseNotes.length - 1];
                const versionKey = version.replace(/\./g, '-');
                const message = this.transloco.translate(`release-notes.version.${versionKey}`, { version });
                const action = this.transloco.translate('release-notes.dismiss');
                this.snackBar.open(message, action, { duration: 6000 });

                localStorage.setItem('version', currentVersion);
            }
        }
    }

    private getNewReleaseNotes(lastKnownVersion: string): string[] {
        const [currentMajor, currentMinor, currentPatch] = lastKnownVersion.split('.').map(Number);
        return this.releasedVersions.filter(version => {
            const [major, minor, patch] = version.split('.').map(Number);
            return major > currentMajor || (major === currentMajor && minor > currentMinor) || (major === currentMajor && minor === currentMinor && patch > currentPatch);
        });
    }
}
