import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet, RouterLink } from '@angular/router';
import { delay, filter, map, of, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { isFirstTimeUser } from '../first-time-user/is-first-time-user';
import { Hotkeys } from '../services/hotkeys.service';
import { ItemsStoreService } from '../services/items-store.service';
import { routeTransitionAnimations } from './route-transition-animations';
import { TabLink } from './tab-link';
import { TranslocoPipe } from '@jsverse/transloco';
import { FirstTimeUserBarComponent } from './first-time-user-bar/first-time-user-bar.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],
  animations: [routeTransitionAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatTabsModule,
    NgFor,
    RouterLink,
    MatIconModule,
    MatBadgeModule,
    NgIf,
    RouterOutlet,
    FirstTimeUserBarComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class ShellComponent implements OnInit, OnDestroy {
  links: TabLink[] = [
    {
      path: 'shoppinglist',
      label: 'shopping-list',
      icon: 'shopping_cart_outline',
      shortcutKey: 'l',
      badgeCount$: this.itemsStore.itemsOnShoppingList$.pipe(
        map((items) => {
          const itemsOnShoppingList = items.filter(
            (item) => !item.isInShoppingCart
          ).length;

          return itemsOnShoppingList > 0 ? itemsOnShoppingList : null;
        })
      ),
    },
    {
      path: 'items',
      label: 'items',
      icon: 'inventory_outline',
      shortcutKey: 'i',
      badgeCount$: of(null),
    },
    {
      path: 'recipes',
      label: 'recipes.recipes',
      icon: 'restaurant_outline',
      shortcutKey: 'r',
      badgeCount$: of(null),
    },
    {
      path: 'stores',
      label: 'stores',
      icon: 'storefront_outline',
      shortcutKey: 's',
      badgeCount$: of(null),
    },
    {
      path: 'settings',
      label: 'settings.settings',
      icon: 'settings',
      shortcutKey: 'x',
      badgeCount$: of(null),
    },
  ];
  activeLink?: TabLink;
  wideScreen?: BreakpointState;

  private destroyed$ = new Subject<void>();
  private lastUrl = '';
  private routePositions = new Map<string, number>();

  @ViewChild('scrollContent', { static: true }) scrollContent!: ElementRef;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private itemsStore: ItemsStoreService,
    private http: HttpClient,
    private hotkeys: Hotkeys
  ) {
    breakpointObserver
      .observe('(min-width: 700px)')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => (this.wideScreen = res));

    this.updateActiveLinkOnNavigation();
    this.updateScrollPositionOnNavigation();
    this.registerHotkeys();
  }

  ngOnInit(): void {
    this.checkNetworkStatus();

    if (!this.activeLink) {
      const routerPath = this.router.routerState?.snapshot?.url;
      this.activeLink = this.links.find((l) => routerPath.split('/')[2] === l.path);
    }
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (document.visibilityState === 'visible') {
      this.checkNetworkStatus();
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  isFirstTimeUser(): boolean {
    return isFirstTimeUser();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private checkNetworkStatus() {
    this.http.get(`${environment.apiUrl}/health`).pipe(
      takeUntil(this.destroyed$),
    ).subscribe();
  }

  /**
   * The user may use the back/forward button (or similar gestures) to navigate,
   * but the active link indicator of the tab bar does not respond to this. So
   * we must manually update the activeLink after navigation to reflect the
   * correct current page.
   */
  private updateActiveLinkOnNavigation() {
    this.router.events
      .pipe(filter((e): e is NavigationStart => e instanceof NavigationStart))
      .pipe(delay(1), takeUntil(this.destroyed$))
      .subscribe((e: NavigationStart) => {
        if (e.navigationTrigger === 'popstate') {
          // e.url looks like "/app/recipe/12345/edit
          // Splitting it on  '/'  yields  ["", "app", "recipe", "12345", "edit"]
          // So we need to look for index 2 to find our relevant path.
          const path = e.url.split('/')[2];
          this.activeLink = this.links.find(l => l.path === path);
        }
      });
  }

  private updateScrollPositionOnNavigation() {
    this.router.events.pipe(
      filter((events) => events instanceof NavigationStart || events instanceof NavigationEnd)
    ).subscribe(event => {
      if (event instanceof NavigationStart) {
        // Store the new position for the previous URL, the current event is for where we are going to.
        const routeKey = this.getKeyForUrl(this.lastUrl);
        this.routePositions.set(routeKey, this.scrollContent.nativeElement.scrollTop);

        this.lastUrl = event.url;
      } else if (event instanceof NavigationEnd) {
        const routeKey = this.getKeyForUrl(event.url);

        if (this.routePositions.has(routeKey)) {
          requestAnimationFrame(() => this.scrollContent.nativeElement.scrollTo({ top: this.routePositions.get(routeKey) }));
        } else {
          requestAnimationFrame(() => this.scrollContent.nativeElement.scrollTo({ top: 0 }));
        }

        this.lastUrl = event.url;
      }
    })
  }

  private getKeyForUrl(url: string): string {
    return url.replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, '');
  }

  private registerHotkeys(): void {
    this.links.forEach((link) => {
      this.hotkeys.addShortcut({
        keys: 'meta.shift.' + link.shortcutKey,
        description: link.label
      })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.activeLink = link;
          this.router.navigate(['/app', link.path]);
        });
    });
  }
}
